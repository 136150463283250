import APP_ERROR_CODES from "../constants/app_error_codes";
import urlTemplate from "url-template";

export function getErrorMessage(errorCode: number) {
  return APP_ERROR_CODES[errorCode]
    ? APP_ERROR_CODES[errorCode]
    : APP_ERROR_CODES[0];
}

export function parseUrlTemplate(
  templateString: string,
  pathParams: { [key: string]: any }
) {
  const template = urlTemplate.parse(templateString);
  return template.expand(pathParams);
}

export function buildShippingAddressLines(shippingAddress: ShippingAddress) {
  const {
    name,
    surname,
    nat,
    floor,
    street,
    city,
    province,
    country,
    postalCode
  } = shippingAddress;
  const shippingAddressLines: string[] = [];
  const line1 = surname ? `${name} ${surname}` : name;
  shippingAddressLines.push(line1);

  let line2 = nat;
  if (floor) {
    line2 = line2 ? `${line2}, ${floor}` : floor;
  }
  if (street) {
    line2 = line2 ? `${line2}, ${street}` : street;
  }

  if (line2) {
    shippingAddressLines.push(line2);
  }

  let line3 = city;
  if (province) {
    line3 = line3 ? `${line3}, ${province}` : province;
  }
  if (postalCode) {
    line3 = line3 ? `${line3} - ${postalCode}` : postalCode;
  }

  if (line3) {
    shippingAddressLines.push(line3);
  }

  if (country) {
    shippingAddressLines.push(country);
  }
  return shippingAddressLines;
}

export function titleCase(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
