import {
  FETCH_PRODUCT,
  PENDING,
  FULFILLED,
  REJECTED,
  UPLOAD_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE
} from "../constants/action-types";

const initialState: ProductState = {
  loading: false,
  uploading: false,
  uploadError: null,
  deleting: false,
  deletingError: null
};
const product = (state = initialState, action: Action): ProductState => {
  switch (action.type) {
    case FETCH_PRODUCT + PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_PRODUCT + FULFILLED: {
      const { payload, success } = action.payload.data;
      if (success) {
        return {
          ...state,
          loading: false,
          product: payload
        };
      } else {
        return {
          ...state,
          loading: false,
          product: undefined
        };
      }
    }
    case FETCH_PRODUCT + REJECTED: {
      return {
        ...state,
        loading: false,
        product: undefined
      };
    }
    case UPLOAD_PRODUCT_IMAGE + PENDING: {
      return {
        ...state,
        uploading: true,
        uploadError: null
      };
    }
    case UPLOAD_PRODUCT_IMAGE + FULFILLED: {
      const { payload, success } = action.payload.data;
      if (success) {
        return {
          ...state,
          uploading: false,
          product: payload,
          uploadError: null
        };
      } else {
        return {
          ...state,
          uploading: false,
          uploadError: "Unable to upload."
        };
      }
    }
    case UPLOAD_PRODUCT_IMAGE + REJECTED: {
      return {
        ...state,
        uploading: false,
        uploadError: "Unable to upload."
      };
    }

    case DELETE_PRODUCT_IMAGE + PENDING: {
      return {
        ...state,
        deleting: true,
        deletingError: null
      };
    }
    case DELETE_PRODUCT_IMAGE + FULFILLED: {
      const { payload, success } = action.payload.data;
      if (success) {
        return {
          ...state,
          product: payload,
          deleting: false,
          deletingError: null
        };
      } else {
        return {
          ...state,
          deleting: false,
          deletingError: "Unable to Delete."
        };
      }
    }
    case DELETE_PRODUCT_IMAGE + REJECTED: {
      return {
        ...state,
        deleting: false,
        deletingError: "Unable to Delete."
      };
    }
  }
  return state;
};
export default product;
