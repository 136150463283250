/**
 * 100+ - REGISTRATION ERROR CODES
 * 200+ - LOGIN ERROR CODES
 * 300+ - RESET Password ERROR CODES
 * 500+ - CART ERROR CODES
 */
const APP_ERROR_CODES: { [key: string]: string } = {
  0: "Something went wrong! please try after sometime.",
  201: "Invalid Email/Password.",
  204: "User is not admin."
};
export default APP_ERROR_CODES;
