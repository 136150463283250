export const PENDING = "_PENDING";
export const FULFILLED = "_FULFILLED";
export const REJECTED = "_REJECTED";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const UPLOAD_PRODUCT_IMAGE = "UPLOAD_PRODUCT_IMAGE";
export const DELETE_PRODUCT_IMAGE = "DELETE_PRODUCT_IMAGE";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_COUPONS = "FETCH_COUPONS";

export const ADD_COUPON = "ADD_COUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";


export const FETCH_SELLERS = "FETCH_SELLERS";
export const ADD_SELLER = "ADD_SELLER";
export const UPDATE_SELLER = "UPDATE_SELLER";
export const DELETE_SELLER = "DELETE_SELLER";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
