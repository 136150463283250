import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { addNewSeller, updateSeller } from "../../actions";
import {
  Modal,
  Spinner,
  ModalFooter,
  Button,
  Form,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";

const sellerValidationSchema = yup.object().shape<Partial<SellerForm>>({
  name: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(3, "Seller name should contain min ${min} chars")
    // eslint-disable-next-line no-template-curly-in-string
    .max(32, "Seller name can have max ${max} chars")
    .required("Name is required."),
  surname: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(3, "Seller surname should contain min ${min} chars")
    // eslint-disable-next-line no-template-curly-in-string
    .max(32, "Seller surname can have max ${max} chars")
    .required("Surname is required."),
  phone: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(9, "Seller phone should contain min ${min} chars")
    // eslint-disable-next-line no-template-curly-in-string
    .max(14, "Seller phone should contain min ${max} chars"),
  email: yup
    .string()
    .email()
    .required("Email is required"),
  address: yup
    .object().shape({
      street: yup.string(),
      city: yup.string(),
      postalCode: yup.string(),
      country: yup.string(),
      countryCode: yup.string(),
    })

});

interface SellerFormProps {
  seller?: Seller;
  modal: boolean;
  onClose: (shouldReload: boolean) => void;
}

const SellerForm: React.FC<SellerFormProps> = props => {
  const { modal, onClose, seller } = props;
  const dispatch = useDispatch();

  const {
    handleSubmit,
    errors,
    values,
    setValues,
    resetForm,
    handleChange
  } = useFormik<SellerForm>({
    initialValues: {
      name: "",
      surname: "",
      phone: "",
      email: "",
      address: {
        street: "",
        city: "",
        postalCode: "",
        country: "",
        countryCode: ""
      },
      categories: [],
      datePrivacyPolicyAccept: null,
      internationalShipment: false,
      privacyPolicyAccept: false,
      random5User: null,
      returnPolicy: false,
      paypalUser: null,
      sustainablePackaging: false
    },
    validationSchema: sellerValidationSchema,
    validateOnChange: false,
    onSubmit: (data: SellerForm) => {
      if (seller && seller.id) {
        dispatch(updateSeller(seller.id, data));
      } else {
        dispatch(addNewSeller(data));
      }
    }
  });

  const { pendingSave } = useSelector<StoreState, SellersState>(
    state => state.sellers
  );

  const pendingSaveRef = useRef(false);

  useEffect(() => {
    if (seller) {
      const {
        name,
        surname,
        phone,
        email,
        address,
        categories,
        datePrivacyPolicyAccept,
        internationalShipment,
        privacyPolicyAccept,
        random5User,
        returnPolicy,
        paypalUser,
        sustainablePackaging
      } = seller;

      setValues(
        {
          name,
          surname,
          phone,
          email,
          address,
          categories,
          datePrivacyPolicyAccept,
          internationalShipment,
          privacyPolicyAccept,
          random5User,
          returnPolicy,
          paypalUser,
          sustainablePackaging
        },
        false
      );
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  useEffect(() => {
    if (pendingSaveRef.current === true && pendingSave === false) {
      onClose(true);
    }
    pendingSaveRef.current = pendingSave;
  }, [pendingSave, pendingSaveRef, onClose]);

  return (
    <Modal isOpen={modal} size="lg" autoFocus={false}>
      <LoadingOverlay
        active={pendingSave}
        spinner={<Spinner style={{ width: "3rem", height: "3rem" }} />}
        text="Saving Seller"
      ></LoadingOverlay>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>{seller ? "Update Seller" : "New Seller"}</ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Seller name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Seller's name"
                  onChange={handleChange}
                  value={values.name}
                  invalid={errors.name ? true : false}
                  autoFocus
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="surname">Seller surname</Label>
                <Input
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Seller's surname"
                  onChange={handleChange}
                  value={values.surname}
                  invalid={errors.surname ? true : false}
                  autoFocus
                />
                <FormFeedback>{errors.surname}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
                <FormGroup>
                  <Label for="email">E-mail</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Seller's email"
                    onChange={handleChange}
                    value={values.email}
                    invalid={errors.email ? true : false}
                    autoFocus
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Seller's contact phone"
                  onChange={handleChange}
                  value={values.phone}
                  invalid={errors.phone ? true : false}
                  autoFocus
                />
                <FormFeedback>{errors.phone}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
                <FormGroup>
                  <Label for="address.street">Address</Label>
                  <Input
                    type="text"
                    name="address.street"
                    id="address.street"
                    placeholder="Street"
                    onChange={handleChange}
                    value={values.address.street}
                    invalid={errors.address ? true : false}
                    autoFocus
                  />
                  <FormFeedback>{errors.address}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                    <Label for="address.city">City</Label>
                    <Input
                      type="text"
                      name="address.city"
                      id="address.city"
                      placeholder="City"
                      onChange={handleChange}
                      value={values.address.city}
                      invalid={errors.address ? true : false}
                      autoFocus
                    />
                  </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="address.postalCode">Postal Code</Label>
                  <Input
                    type="text"
                    name="address.postalCode"
                    id="address.postalCode"
                    placeholder="Postal Code"
                    onChange={handleChange}
                    value={values.address.postalCode}
                    invalid={errors.address ? true : false}
                    autoFocus
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="address.country">Country</Label>
                  <Input
                    type="text"
                    name="address.country"
                    id="address.country"
                    placeholder="Country Name"
                    onChange={handleChange}
                    value={values.address.country}
                    invalid={errors.address ? true : false}
                    autoFocus
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                    <Label for="address.countryCode">Country code</Label>
                    <Input
                      type="text"
                      name="address.countryCode"
                      id="address.countryCode"
                      placeholder="countryCode"
                      onChange={handleChange}
                      value={values.address.countryCode}
                      invalid={errors.address ? true : false}
                      autoFocus
                    />
                  </FormGroup>
              </Col>
          </Row>
          <Row form>
              <span>Categories</span>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Save</Button>
          <Button color="secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default SellerForm;
