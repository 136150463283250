import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef
} from "react";
import { Row, Col, Spinner, Button, Form, FormGroup, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchSellers, deleteSeller } from "../../actions";
import DataTable from "react-data-table-component";
import moment from "moment";
import { debounce } from "debounce";
import SellerForm from "./SellerForm";
const Sellers: React.FC<void> = () => {
  const { sellers, loading, total, deletePending, page, limit } = useSelector<
    StoreState,
    SellersState
  >(state => state.sellers);
  const [filterCode, setFilterCode] = useState("");
  const dispatch = useDispatch();

  const fetchSellersAction = useCallback(
    (code: string, page: number, limit: number) =>
      dispatch(fetchSellers(code, page, limit)),
    [dispatch]
  );

  const fetchSellersActionDebounced = useMemo(
    () => debounce(fetchSellersAction, 300),
    [fetchSellersAction]
  );

  useEffect(() => {
    fetchSellersActionDebounced(filterCode, 1, 10);
  }, [filterCode, fetchSellersActionDebounced]);

  const [showSellerForm, setShowSellerForm] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState<Seller>();

  const handleOnSellerEdit = useCallback((seller: Seller) => {
    setSelectedSeller(seller);
    setShowSellerForm(true);
  }, []);

  const handleOnSellerDelete = useCallback(
    (id: string) => {
      dispatch(deleteSeller(id));
    },
    [dispatch]
  );

  const showNewSellerForm = useCallback(() => {
    setSelectedSeller(undefined);
    setShowSellerForm(true);
  }, []);

  const deletePendingRef = useRef(false);

  useEffect(() => {
    if (deletePendingRef.current === true && deletePending === false) {
      fetchSellersAction(filterCode, page, limit);
    }
    deletePendingRef.current = deletePending;
  }, [deletePending, filterCode, page, limit, fetchSellersAction]);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: "name"
      },
      {
        name: "Surname",
        selector: "surname"
      },
      {
        name: "Phone",
        selector: "phone"
      },
      {
        name: "Email",
        selector: "email"
      },
      {
        name: "",
        selector: "",
        cell: (seller: Seller) => (
          <>
            <Button
              color="primary"
              size="sm"
              title="Edit Seller"
              style={{ marginRight: 5 }}
              onClick={() => handleOnSellerEdit(seller)}
            >
              <i className="cui-pencil icons" />
            </Button>
            <Button
              color="danger"
              size="sm"
              title="Delete Seller"
              onClick={() => handleOnSellerDelete(seller.id)}
            >
              <i className="cui-trash icons" />
            </Button>
          </>
        )
      }
    ],
    [handleOnSellerEdit, handleOnSellerDelete]
  );

  const subHeader = useMemo(
    () => (
      <>
        <Form inline>
          <FormGroup row>
            <Col>
              <Input
                type="text"
                name="code"
                id="code"
                placeholder="Search Seller Code"
                value={filterCode}
                onChange={event => {
                  setFilterCode(event.target.value);
                }}
              />
            </Col>
          </FormGroup>
        </Form>
        <Button
          color="primary"
          className="float-right"
          style={{ marginLeft: 10 }}
          title="Add Seller"
          onClick={() => showNewSellerForm()}
        >
          <i className="fa fa-plus-square" />
        </Button>
      </>
    ),
    [filterCode, showNewSellerForm]
  );

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg="12">
          <DataTable
            title="Sellers"
            keyField="id"
            subHeader
            subHeaderComponent={subHeader}
            columns={columns}
            data={sellers}
            pagination
            striped
            progressPending={loading}
            progressComponent={<Spinner />}
            paginationServer
            onChangePage={(page, _) =>
              fetchSellersActionDebounced(filterCode, page, limit)
            }
            paginationTotalRows={total}
            onChangeRowsPerPage={(currentRowsPerPage, curentPage) =>
              fetchSellersActionDebounced(
                filterCode,
                curentPage,
                currentRowsPerPage
              )
            }
          />
        </Col>
      </Row>
      {showSellerForm ? (
        <SellerForm
          modal={showSellerForm}
          onClose={shouldReload => {
            setShowSellerForm(false);
            setSelectedSeller(undefined);
            if (shouldReload) {
              fetchSellersActionDebounced(filterCode, page, limit);
            }
          }}
          seller={selectedSeller}
        />
      ) : null}
    </div>
  );
};
export default Sellers;
