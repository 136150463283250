import { combineReducers } from "redux";
import products from "./products";
import product from "./product";
import auth from "./auth";
import orders from "./orders";
import coupons from "./coupons";
import categories from "./categories";
import sellers from "./sellers";

export default combineReducers({ products, product, auth, orders, coupons, categories, sellers });
