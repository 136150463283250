import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import "./App.scss";
import { Spinner } from "reactstrap";
import { renderRoutes } from "react-router-config";
import routes from "./routes";

const loading = () => <Spinner type="grow" color="primary" />;

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          {renderRoutes(routes)}
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
