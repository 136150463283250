import {
  FETCH_SELLERS,
  PENDING,
  FULFILLED,
  REJECTED,
  ADD_SELLER,
  UPDATE_SELLER,
  DELETE_SELLER
} from "../constants/action-types";

const initialState: SellersState = {
  sellers: [],
  loading: false,
  page: 0,
  limit: 0,
  totalPages: 0,
  total: 0,
  pendingSave: false,
  savingError: null,
  deletePending: false,
  deletingError: null
};
const sellers = (state = initialState, action: Action): SellersState => {
  switch (action.type) {
    case FETCH_SELLERS + PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_SELLERS + FULFILLED: {
      const { page, limit } = action.meta;
      const { data } = action.payload;
      const { totalPages, items, total } = data;
      return {
        ...state,
        loading: false,
        sellers: items,
        totalPages,
        total,
        page,
        limit
      };
    }
    case FETCH_SELLERS + REJECTED: {
      return {
        ...state,
        loading: false,
        sellers: [],
        totalPages: 0,
        total: 0
      };
    }
    case ADD_SELLER + PENDING:
    case UPDATE_SELLER + PENDING: {
      return {
        ...state,
        pendingSave: true
      };
    }
    case ADD_SELLER + FULFILLED:
    case UPDATE_SELLER + FULFILLED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case ADD_SELLER + REJECTED:
    case UPDATE_SELLER + REJECTED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case DELETE_SELLER + PENDING: {
      return {
        ...state,
        deletePending: true
      };
    }
    case DELETE_SELLER + FULFILLED: {
      return {
        ...state,
        deletePending: false
      };
    }
    case DELETE_SELLER + REJECTED: {
      return {
        ...state,
        deletePending: false
      };
    }
  }
  return state;
};

export default sellers;
