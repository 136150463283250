import {
  FETCH_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCT,
  UPLOAD_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  LOGIN,
  LOGOUT,
  FETCH_ORDERS,
  FETCH_COUPONS,
  ADD_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
  FETCH_SELLERS,
  ADD_SELLER,
  UPDATE_SELLER,
  DELETE_SELLER,
  FETCH_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from "../constants/action-types";

import * as API_URLS from "../constants/api_url.constant";
import { axiosSecured, axiosUnsecured } from "../axios";
import { parseUrlTemplate } from "../utils/helpers";

export const fetchProducts = (
  status: string,
  page: number,
  limit: number,
  sortColumn?: string,
  sortOrder?: string
) => {
  return {
    type: FETCH_PRODUCTS,
    payload: axiosSecured.get("products", {
      params: { page, limit, status, sortColumn, sortOrder }
    }),
    meta: {
      page,
      limit
    }
  };
};

export const addNewProduct = (product: Product) => {
  return {
    type: ADD_PRODUCT,
    payload: axiosSecured.post("products", product)
  };
};
export const updateProduct = (id: string, product: Product) => {
  return {
    type: UPDATE_PRODUCT,
    payload: axiosSecured.put(`products/${id}`, product)
  };
};

export const deleteProduct = (id: string) => {
  return {
    type: DELETE_PRODUCT,
    payload: axiosSecured.delete(`products/${id}`)
  };
};

export const fetchProduct = (id: string) => {
  return {
    type: FETCH_PRODUCT,
    payload: axiosSecured.get(`products/${id}`)
  };
};

export const fetchProductImages = (id: string) => {
  return {
    type: FETCH_PRODUCT,
    payload: axiosSecured.get(`products/images/${id}`)
  };
};

export const uploadProductImage = (id: string, imageBlob: Blob) => {
  const formData = new FormData();
  formData.append("file", imageBlob);
  return {
    type: UPLOAD_PRODUCT_IMAGE,
    payload: axiosSecured.post(`products/${id}/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  };
};

export const deleteProductImage = (id: string, imageId: string) => {
  return {
    type: DELETE_PRODUCT_IMAGE,
    payload: axiosSecured.delete(`products/${id}/images/${imageId}`)
  };
};

export function login(email: string, password: string) {
  return {
    type: LOGIN,
    payload: axiosUnsecured.post(API_URLS.ADMIN_LOGIN, { email, password })
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function fetchOrders(
  status: string,
  page: number,
  limit: number,
  sortColumn?: string,
  sortOrder?: string
) {
  return {
    type: FETCH_ORDERS,
    payload: axiosSecured.get(API_URLS.ORDERS, {
      params: {
        page,
        limit,
        status,
        sortColumn,
        sortOrder
      }
    }),
    meta: {
      page,
      limit,
      sortColumn,
      sortOrder
    }
  };
}

export function fetchCoupons(code: string, page: number, limit: number) {
  return {
    type: FETCH_COUPONS,
    payload: axiosSecured.get(API_URLS.COUPONS, {
      params: {
        page,
        limit,
        code
      }
    }),
    meta: {
      page,
      limit
    }
  };
}

export const addNewCoupon = (coupon: CouponForm) => {
  return {
    type: ADD_COUPON,
    payload: axiosSecured.post(API_URLS.COUPONS, coupon)
  };
};
export const updateCoupon = (id: string, coupon: CouponForm) => {
  const url = parseUrlTemplate(API_URLS.COUPON, { id });
  return {
    type: UPDATE_COUPON,
    payload: axiosSecured.put(url, coupon)
  };
};

export const deleteCoupon = (id: string) => {
  const url = parseUrlTemplate(API_URLS.COUPON, { id });
  return {
    type: DELETE_COUPON,
    payload: axiosSecured.delete(url)
  };
};


export function fetchSellers(name: string, page: number, limit: number) {
  return {
    type: FETCH_SELLERS,
    payload: axiosSecured.get(API_URLS.SELLERS, {
      params: {
        page,
        limit,
        name
      }
    }),
    meta: {
      page,
      limit
    }
  };
}

export const addNewSeller = (seller: SellerForm) => {
  return {
    type: ADD_SELLER,
    payload: axiosSecured.post(API_URLS.SELLERS, seller)
  };
};
export const updateSeller = (id: string, seller: SellerForm) => {
  const url = parseUrlTemplate(API_URLS.SELLERS, { id });
  return {
    type: UPDATE_SELLER,
    payload: axiosSecured.put(url, seller)
  };
};

export const deleteSeller = (id: string) => {
  const url = parseUrlTemplate(API_URLS.SELLERS, { id });
  return {
    type: DELETE_SELLER,
    payload: axiosSecured.delete(url)
  };
};


export function fetchCategories(name: string, page: number, limit: number) {
  return {
    type: FETCH_CATEGORIES,
    payload: axiosSecured.get(API_URLS.CATEGORIES, {
      params: {
        page,
        limit,
        name
      }
    }),
    meta: {
      page,
      limit
    }
  };
}

export const addNewCategory = (category: CategoryForm) => {
  return {
    type: ADD_CATEGORY,
    payload: axiosSecured.post(API_URLS.CATEGORIES, category)
  };
};
export const updateCategory = (id: string, category: CategoryForm) => {
  const url = parseUrlTemplate(API_URLS.CATEGORIES, { id });
  return {
    type: UPDATE_CATEGORY,
    payload: axiosSecured.put(url, category)
  };
};

export const deleteCategory = (id: string) => {
  const url = parseUrlTemplate(API_URLS.CATEGORIES, { id });
  return {
    type: DELETE_CATEGORY,
    payload: axiosSecured.delete(url)
  };
};





