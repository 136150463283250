import axios from "axios";
import store from "./store";
import { logout } from "./actions";
import { API_BASE_URL } from "./constants/app.constants";

export const axiosUnsecured = axios.create({
  baseURL: API_BASE_URL
});

export const axiosSecured = axios.create({
  baseURL: API_BASE_URL
});

axiosSecured.interceptors.request.use(
  async config => {
    const token = await localStorage.getItem("userToken");
    const bearerToken = "Bearer " + token;
    config.headers["Authorization"] = bearerToken;
    return config;
  },
  error => Promise.reject(error)
);

axiosSecured.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);
