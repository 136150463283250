import {
  FETCH_COUPONS,
  PENDING,
  FULFILLED,
  REJECTED,
  ADD_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON
} from "../constants/action-types";

const initialState: CouponsState = {
  coupons: [],
  loading: false,
  page: 0,
  limit: 0,
  totalPages: 0,
  total: 0,
  pendingSave: false,
  savingError: null,
  deletePending: false,
  deletingError: null
};
const coupons = (state = initialState, action: Action): CouponsState => {
  switch (action.type) {
    case FETCH_COUPONS + PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_COUPONS + FULFILLED: {
      const { page, limit } = action.meta;
      const { data } = action.payload;
      const { totalPages, items, total } = data;
      return {
        ...state,
        loading: false,
        coupons: items,
        totalPages,
        total,
        page,
        limit
      };
    }
    case FETCH_COUPONS + REJECTED: {
      return {
        ...state,
        loading: false,
        coupons: [],
        totalPages: 0,
        total: 0
      };
    }
    case ADD_COUPON + PENDING:
    case UPDATE_COUPON + PENDING: {
      return {
        ...state,
        pendingSave: true
      };
    }
    case ADD_COUPON + FULFILLED:
    case UPDATE_COUPON + FULFILLED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case ADD_COUPON + REJECTED:
    case UPDATE_COUPON + REJECTED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case DELETE_COUPON + PENDING: {
      return {
        ...state,
        deletePending: true
      };
    }
    case DELETE_COUPON + FULFILLED: {
      return {
        ...state,
        deletePending: false
      };
    }
    case DELETE_COUPON + REJECTED: {
      return {
        ...state,
        deletePending: false
      };
    }
  }
  return state;
};

export default coupons;
