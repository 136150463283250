import {
  FETCH_CATEGORIES,
  PENDING,
  FULFILLED,
  REJECTED,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from "../constants/action-types";

const initialState: CategoriesState = {
  categories: [],
  loading: false,
  page: 0,
  limit: 0,
  totalPages: 0,
  total: 0,
  pendingSave: false,
  savingError: null,
  deletePending: false,
  deletingError: null
};
const categories = (state = initialState, action: Action): CategoriesState => {
  switch (action.type) {
    case FETCH_CATEGORIES + PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_CATEGORIES + FULFILLED: {
      const { page, limit } = action.meta;
      const { data } = action.payload;
      const { totalPages, items, total } = data;
      return {
        ...state,
        loading: false,
        categories: items,
        totalPages,
        total,
        page,
        limit
      };
    }
    case FETCH_CATEGORIES + REJECTED: {
      return {
        ...state,
        loading: false,
        categories: [],
        totalPages: 0,
        total: 0
      };
    }
    case ADD_CATEGORY + PENDING:
    case UPDATE_CATEGORY + PENDING: {
      return {
        ...state,
        pendingSave: true
      };
    }
    case ADD_CATEGORY + FULFILLED:
    case UPDATE_CATEGORY + FULFILLED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case ADD_CATEGORY + REJECTED:
    case UPDATE_CATEGORY + REJECTED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case DELETE_CATEGORY + PENDING: {
      return {
        ...state,
        deletePending: true
      };
    }
    case DELETE_CATEGORY + FULFILLED: {
      return {
        ...state,
        deletePending: false
      };
    }
    case DELETE_CATEGORY + REJECTED: {
      return {
        ...state,
        deletePending: false
      };
    }
  }
  return state;
};

export default categories;
