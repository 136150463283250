import {
  FETCH_PRODUCTS,
  PENDING,
  FULFILLED,
  REJECTED,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from "../constants/action-types";

const initialState: ProductsState = {
  products: [],
  loading: false,
  page: 0,
  limit: 0,
  totalPages: 0,
  total: 0,
  pendingSave: false,
  savingError: null,
  deletePending: false,
  deletingError: null
};
const products = (state = initialState, action: Action): ProductsState => {
  switch (action.type) {
    case FETCH_PRODUCTS + PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_PRODUCTS + FULFILLED: {
      const { page, limit } = action.meta;
      const { data } = action.payload;
      const { totalPages, items, total } = data;
      return {
        ...state,
        loading: false,
        products: items,
        totalPages,
        total,
        page,
        limit
      };
    }
    case FETCH_PRODUCTS + REJECTED: {
      return {
        ...state,
        loading: false,
        products: [],
        totalPages: 0,
        total: 0
      };
    }
    case ADD_PRODUCT + PENDING:
    case UPDATE_PRODUCT + PENDING: {
      return {
        ...state,
        pendingSave: true
      };
    }
    case ADD_PRODUCT + FULFILLED:
    case UPDATE_PRODUCT + FULFILLED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case ADD_PRODUCT + REJECTED:
    case UPDATE_PRODUCT + REJECTED: {
      return {
        ...state,
        pendingSave: false
      };
    }
    case DELETE_PRODUCT + PENDING: {
      return {
        ...state,
        deletePending: true
      };
    }
    case DELETE_PRODUCT + FULFILLED: {
      return {
        ...state,
        deletePending: false
      };
    }
    case DELETE_PRODUCT + REJECTED: {
      return {
        ...state,
        deletePending: false
      };
    }
  }
  return state;
};

export default products;
