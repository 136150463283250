export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const PRODUCT_SIZES = [
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "Unique Size",
  "36.5 EU - 4 UK",
  "37 EU - 4.5 UK",
  "37.5 EU - 5 UK",
  "38 EU - 5.5 UK",
  "38.5 EU - 6 UK",
  "39 EU - 6.5 UK",
  "39.5 EU - 7 UK",
  "40 EU - 7.5 UK",
  "40.5 EU - 8 UK",
  "41 EU - 8.5 UK",
  "41.5 EU - 9 UK",
  "42 EU - 9.5 UK",
  "42.5 EU - 10 UK",
  "43 - 10.5 UK"
];

export const PRODUCT_STATUS_CODES = [
  "AVAILABLE",
  "UNAVAILABLE",
  "IN_CART",
  "ORDER_PENDING",
  "ORDER_COMPLETE"
];

export const ORDER_STATUS_CODES = [
  "PAYMENT_SUCCEEDED",
  "CREATED",
  "PAYMENT_FAILED",
  "CANCELLED"
];

export const ORDER_STATUS_MAP: { [key: string]: string } = {
  PAYMENT_SUCCEEDED: "Payment Completed",
  CREATED: "Order Created",
  PAYMENT_FAILED: "Payment Failed",
  CANCELLED: "Order Cancelled"
};

export const PRODUCT_STATUS_MAP: { [key: string]: string } = {
  AVAILABLE: "Available",
  UNAVAILABLE: "Unavailable",
  IN_CART: "In Cart",
  ORDER_PENDING: "Order Pending",
  ORDER_COMPLETE: "Order Completed"
};
