import {
  FETCH_ORDERS,
  PENDING,
  FULFILLED,
  REJECTED
} from "../constants/action-types";

const initialState: OrdersState = {
  orders: [],
  loading: false,
  page: 0,
  limit: 0,
  totalPages: 0,
  total: 0
};
const orders = (state = initialState, action: Action): OrdersState => {
  switch (action.type) {
    case FETCH_ORDERS + PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_ORDERS + FULFILLED: {
      const { page, limit } = action.meta;
      const { data } = action.payload;
      const { totalPages, items, total } = data;
      return {
        ...state,
        loading: false,
        orders: items,
        totalPages,
        total,
        page,
        limit
      };
    }
    case FETCH_ORDERS + REJECTED: {
      return {
        ...state,
        loading: false,
        orders: [],
        totalPages: 0,
        total: 0
      };
    }
  }
  return state;
};

export default orders;
