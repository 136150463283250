import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef
} from "react";
import { Row, Col, Spinner, Button, Form, FormGroup, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories, deleteCategory } from "../../actions";
import DataTable from "react-data-table-component";
import { debounce } from "debounce";
import CategoryForm from "./CategoryForm";
const Categories: React.FC<void> = () => {
  const { categories, loading, total, deletePending, page, limit } = useSelector<
    StoreState,
    CategoriesState
  >(state => state.categories);
  const [filterName, setFilterName] = useState("");
  const dispatch = useDispatch();

  const fetchCategoriesAction = useCallback(
    (name: string, page: number, limit: number) =>
      dispatch(fetchCategories(name, page, limit)),
    [dispatch]
  );

  const fetchCategoriesActionDebounced = useMemo(
    () => debounce(fetchCategoriesAction, 300),
    [fetchCategoriesAction]
  );

  useEffect(() => {
    fetchCategoriesActionDebounced(filterName, 1, 10);
  }, [filterName, fetchCategoriesActionDebounced]);

  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const handleOnCategoryEdit = useCallback((category: Category) => {
    setSelectedCategory(category);
    setShowCategoryForm(true);
  }, []);

  const handleOnCategoryDelete = useCallback(
    (id: string) => {
      dispatch(deleteCategory(id));
    },
    [dispatch]
  );

  const showNewCategoryForm = useCallback(() => {
    setSelectedCategory(undefined);
    setShowCategoryForm(true);
  }, []);

  const deletePendingRef = useRef(false);

  useEffect(() => {
    if (deletePendingRef.current === true && deletePending === false) {
      fetchCategoriesAction(filterName, page, limit);
    }
    deletePendingRef.current = deletePending;
  }, [deletePending, filterName, page, limit, fetchCategoriesAction]);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: "name"
      },
      {
        name: "Locale",
        selector: "locale"
      },
      {
        name: "",
        selector: "",
        cell: (category: Category) => (
          <>
            <Button
              color="primary"
              size="sm"
              title="Edit Category"
              style={{ marginRight: 5 }}
              onClick={() => handleOnCategoryEdit(category)}
            >
              <i className="cui-pencil icons" />
            </Button>
            <Button
              color="danger"
              size="sm"
              title="Delete Category"
              onClick={() => handleOnCategoryDelete(category.id)}
            >
              <i className="cui-trash icons" />
            </Button>
          </>
        )
      }
    ],
    [handleOnCategoryEdit, handleOnCategoryDelete]
  );

  const subHeader = useMemo(
    () => (
      <>
        <Form inline>
          <FormGroup row>
            <Col>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Search Category Name"
                value={filterName}
                onChange={event => {
                  setFilterName(event.target.value);
                }}
              />
            </Col>
          </FormGroup>
        </Form>
        <Button
          color="primary"
          className="float-right"
          style={{ marginLeft: 10 }}
          title="Add Category"
          onClick={() => showNewCategoryForm()}
        >
          <i className="fa fa-plus-square" />
        </Button>
      </>
    ),
    [filterName, showNewCategoryForm]
  );

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg="12">
          <DataTable
            title="Categories"
            keyField="id"
            subHeader
            subHeaderComponent={subHeader}
            columns={columns}
            data={categories}
            pagination
            striped
            progressPending={loading}
            progressComponent={<Spinner />}
            paginationServer
            onChangePage={(page, _) =>
              fetchCategoriesActionDebounced(filterName, page, limit)
            }
            paginationTotalRows={total}
            onChangeRowsPerPage={(currentRowsPerPage, curentPage) =>
              fetchCategoriesActionDebounced(
                filterName,
                curentPage,
                currentRowsPerPage
              )
            }
          />
        </Col>
      </Row>
      {showCategoryForm ? (
        <CategoryForm
          modal={showCategoryForm}
          onClose={shouldReload => {
            setShowCategoryForm(false);
            setSelectedCategory(undefined);
            if (shouldReload) {
              fetchCategoriesActionDebounced(filterName, page, limit);
            }
          }}
          category={selectedCategory}
        />
      ) : null}
    </div>
  );
};
export default Categories;
