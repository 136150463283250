import React from "react";
import { RouteConfig } from "react-router-config";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import Categories from "./views/Categories";
import Sellers from "./views/Sellers";

const Dashboard = React.lazy(() => import("./views/Dashboard"));
const Products = React.lazy(() => import("./views/Products"));
const Orders = React.lazy(() => import("./views/Orders"));
const Login = React.lazy(() => import("./views/Login"));
const Coupons = React.lazy(() => import("./views/Coupons"));
const DefaultLayout = React.lazy(() =>
  import("./containers/DefaultLayout/DefaultLayout")
);

const ProtectedRoute = ({
  protectedComponent: ProtectedComponent,
  ...otherProps
}) => {
  const { userToken } = useSelector<StoreState, AuthState>(state => state.auth);
  if (userToken) {
    return <ProtectedComponent {...otherProps} />;
  }
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: otherProps.location }
      }}
    />
  );
};

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes: RouteConfig = [
  {
    path: "/login",
    name: "Login",
    exact: true,
    component: Login
  },
  {
    path: "/",
    name: "Root",
    component: props => (
      <ProtectedRoute protectedComponent={DefaultLayout} {...props} />
    ),
    routes: [
      {
        path: "/",
        name: "Home",
        exact: true,
        component: () => <Redirect to="/products" />
      },
      { path: "/dashboard", name: "Dashboard", component: Dashboard },
      { path: "/products", name: "Products", component: Products },
      { path: "/sellers", name: "Sellers", component: Sellers },
      { path: "/categories", name: "Categories", component: Categories },
      { path: "/orders", name: "Orders", component: Orders },
      { path: "/coupons", name: "Coupons", component: Coupons }
    ]
  }
];

export default routes;
