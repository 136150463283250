import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { addNewCategory, updateCategory } from "../../actions";
import {
  Modal,
  Spinner,
  ModalFooter,
  Button,
  Form,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
//@ts-ignore
import LoadingOverlay from "react-loading-overlay";

const categoryValidationSchema = yup.object().shape<Partial<CategoryForm>>({
  name: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(3, "Category name should contain min ${min} chars")
    // eslint-disable-next-line no-template-curly-in-string
    .max(32, "Category name can have max ${max} chars")
    .required("Coupon code is required."),
  locale: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(2, "Must be equal to ${max} chars.")
    // eslint-disable-next-line no-template-curly-in-string
    .max(2, "Must be equal to ${max} chars.")
    .required("Locale is required."),
});

interface CategoryFormProps {
  category?: Category;
  modal: boolean;
  onClose: (shouldReload: boolean) => void;
}

const CategoryForm: React.FC<CategoryFormProps> = props => {
  const { modal, onClose, category } = props;
  const dispatch = useDispatch();

  const {
    handleSubmit,
    errors,
    values,
    setValues,
    resetForm,
    handleChange
  } = useFormik<CategoryForm>({
    initialValues: {
      name: "",
      locale: "en",
      translations: []
    },
    validationSchema: categoryValidationSchema,
    validateOnChange: false,
    onSubmit: (data: CategoryForm) => {
      if (category && category.id) {
        dispatch(updateCategory(category.id, data));
      } else {
        dispatch(addNewCategory(data));
      }
    }
  });

  const { pendingSave } = useSelector<StoreState, CouponsState>(
    state => state.coupons
  );

  const pendingSaveRef = useRef(false);

  useEffect(() => {
    if (category) {
      const {
        name,
        locale,
        translations
      } = category;

      setValues(
        {
          name,
          locale,
          translations
        },
        false
      );
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (pendingSaveRef.current === true && pendingSave === false) {
      onClose(true);
    }
    pendingSaveRef.current = pendingSave;
  }, [pendingSave, pendingSaveRef, onClose]);

  return (
    <Modal isOpen={modal} size="lg" autoFocus={false}>
      <LoadingOverlay
        active={pendingSave}
        spinner={<Spinner style={{ width: "3rem", height: "3rem" }} />}
        text="Saving Category"
      ></LoadingOverlay>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>{category ? "Update Category" : "New Category"}</ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Category name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Category's name"
                  onChange={handleChange}
                  value={values.name}
                  invalid={errors.name ? true : false}
                  autoFocus
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
              <Label for="name">Locale</Label>
                <Input
                  type="text"
                  name="locale"
                  id="locale"
                  placeholder="Locale (default en)"
                  onChange={handleChange}
                  value={values.locale}
                  invalid={errors.locale ? true : false}
                  autoFocus
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Save</Button>
          <Button color="secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default CategoryForm;
