import {
  PENDING,
  FULFILLED,
  REJECTED,
  LOGIN,
  LOGOUT
} from "../constants/action-types";

const initialState: AuthState = {
  loading: false,
  userToken: localStorage.getItem("userToken")
};
const auth = (state = initialState, action: Action): AuthState => {
  switch (action.type) {
    case LOGIN + PENDING: {
      return { loading: true, userToken: null, success: false };
    }
    case LOGIN + FULFILLED: {
      const data: LoginResponse = action.payload.data;
      if (data.success) {
        const userToken = data.payload.token;
        localStorage.setItem("userToken", userToken);
        return { loading: false, userToken, success: data.success };
      } else {
        return {
          loading: false,
          success: data.success,
          errorCode: data.errorCode,
          userToken: null
        };
      }
    }
    case LOGIN + REJECTED: {
      const { errorCode } = action.payload.response.data;
      return {
        loading: false,
        success: false,
        errorCode: errorCode,
        userToken: null
      };
    }
    case LOGOUT: {
      localStorage.removeItem("userToken");
      return {
        loading: false,
        userToken: null
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;
